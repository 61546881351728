<template>
  <TheContent
    title="User"
    :countTotalItems="countTotalItems"
    :countItems="countItems"
    :countSelectedItems="countSelectedItems"
    :activePage.sync="page"
    :pages="countPages"
    :pageSize.sync="pageSize"
  >
    <CDataTable hover striped sorter :loading="loading" :fields="fields" :items="data" index-column>
      <template #check="data">
        <td>
          <CInputCheckbox
            :checked="checkValue(data.item.id)"
            @update:checked="(id) => handlerChose(data.item.id)"
          />
        </td>
      </template>

      <template #status_text="data">
        <td>
          <CBadge :color="getBadge(data.item.status_text)">{{data.item.status_text}}</CBadge>
        </td>
      </template>
    </CDataTable>
  </TheContent>
</template>

<script>
import TheContent from "@/containers/TheContent";

import MixinsScreen from "@/mixins/screen";

export default {
  name: "Users",

  mixins: [MixinsScreen],

  components: {
    TheContent
  },

  data: () => {
    return {
      apiName: "users/referrals",
      apiPrefix: "api",

      fields: [
        // { key: "check", label: "", sorter: false },
        // { key: "id", label: "Id" },
        { key: "email", label: "Email" },
        { key: "country", label: "Country" },
        { key: "balance", label: "Balance" },
        { key: "total_balance", label: "Deposits" },
        { key: "status_text", label: "Status" },
        {
          key: "date_joined",
          label: "Created",
          _style: "min-width:140px"
        }
      ],

      filters: {
        dataDefault: {
          id: null,
          user: [],
          country: []
        },

        items: {
          id: {
            type: "select",
            label: "Select id"
          },
          email: {
            type: "complete",
            label: "Select users",
            apiName: "emails",
            size: "3"
          },
          country: {
            type: "complete",
            label: "Select countries",
            apiName: "countries",
            size: "3"
          }
        }
      }
    };
  },

  methods: {
    getBadge(status) {
      return status === "Active" ? "success" : "danger";
    },

    rowClicked(item, index) {
      this.$router.push({ path: `users/${item.id.toString()}` });
    }
  }
};
</script>
